import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { useAccountStore } from "./account";
import { useEventStore } from "/src/stores/events";
import moment from "moment/moment";
import { useColorGameStore } from "./colorGame";
import { useSabongStore } from "./sabong";

import { TouchSwipe } from "quasar";

export const usePlayerStore = defineStore({
  id: "player",

  state: () => ({
    playerStore: usePlayerStore(),
    accountStore: useAccountStore(),
    eventStore: useEventStore(),
    colorGameStore: useColorGameStore(),
    sabongStore: useSabongStore(),

    commissionToday: 0,

    

    meronBet: 0,
    walaBet: 0,
    willWin: 0,
    meronBar: 50,
    walaBar: 50,
    drawBet: 0,
    

    bet: 0,
    potentialWin: 0,
    betHistory: [],

    barStatus: "",

  }),

  actions: {

   

    computeWinnings(round) {
      let meronWinnings =
        this.meronBet *
        ((100 - (this.eventStore.eventDetails.plasada * 2)) / 100 ) *
        round.oddsMeron;

      let walaWinnings =
        this.walaBet *
        ((100 - (this.eventStore.eventDetails.plasada * 2)) / 100 ) *
        round.oddsWala;

      let drawWinnings =
      this.drawBet *
      (1 - this.eventStore.eventDetails.plasada / 100) * 8 - ( this.drawBet *
        ( this.eventStore.eventDetails.plasada / 100));

      this.sabongStore.winning.meron = Math.round(meronWinnings)
      this.sabongStore.winning.wala = Math.round(walaWinnings)
      this.sabongStore.winning.draw = Math.round(drawWinnings)
      // this.willWin = Math.trunc(meronWinnings + walaWinnings);
    },

    clearBets() {
      this.colorGameStore.bets.red = 0;
      this.colorGameStore.bets.green = 0;
      this.colorGameStore.bets.blue = 0;
      this.colorGameStore.bets.yellow = 0;
      this.colorGameStore.bets.white = 0;
      this.colorGameStore.bets.pink = 0;
      this.meronBet = 0;
      this.walaBet = 0;
      this.drawBet = 0;
      this.sabongStore.winning.meron = 0;
      this.sabongStore.winning.wala = 0;
      this.sabongStore.winning.draw = 0;

      this.colorGameStore.bars.red = 16.67,
      this.colorGameStore.bars.green= 16.67,
      this.colorGameStore.bars.blue = 16.67,
      this.colorGameStore.bars.yellow = 16.67,
      this.colorGameStore.bars.white= 16.67,
      this.colorGameStore.bars.pink= 16.67

    },
 
    formatDate(date) {
      return moment(String(date)).format("MM/DD/YY H:mm A");
    },

  

    currentBet(roundNumber) {
      const currentBets = this.betHistory.filter(
        (betData) => betData.roundNumber == roundNumber
      );

      for (let i = 0; i < currentBets.length; i++) {}
    },

    barCompute(round) {
      switch (this.eventStore.eventDetails.gameType) {
        case "sabong":
          const total = round.meronTotal + round.walaTotal;

          this.meronBar = (round.meronTotal / total) * 100;
          this.walaBar = (round.walaTotal / total) * 100;
          break;
        case "color-game":
          const grandTotal =
            round.redTotal +
            round.greenTotal +
            round.blueTotal +
            round.whiteTotal +
            round.yellowTotal +
            round.pinkTotal;
          this.colorGameStore.bars.red = (round.redTotal / grandTotal) * 100;
          this.colorGameStore.bars.green = (round.greenTotal / grandTotal) * 100;
          this.colorGameStore.bars.blue = (round.blueTotal / grandTotal) * 100;
          this.colorGameStore.bars.yellow = (round.yellowTotal / grandTotal) * 100;
          this.colorGameStore.bars.white = (round.whiteTotal / grandTotal) * 100;
          this.colorGameStore.bars.pink = (round.pinkTotal / grandTotal) * 100;
      }
    },

    confirmBet(gameType, bet, choice) {
      
      bet = Number(bet)
   
      
      Swal.fire({
        title: "Are you sure you want to bet " + bet + " on " + choice + "?",

        text: "Your bet won't be refunded after confirming!",
        icon: "warning",
        iconColor: "#F46A6A",
        background: "#2A3042",
        color: "white",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, let's bet!",
      }).then((result) => {
        if (result.value) {
          if (this.accountStore.chips >= bet && bet >= this.eventStore.eventDetails.minimumBet) {
            let betDetails;

            switch (gameType) {
              case "sabong":
                betDetails = {
                  roundNumber: this.eventStore.currentRound.roundNumber,
                  choice: choice,
                  amount: bet,
                };

                break;

              case "color-game":
                let red = 0
                let green = 0
                let blue = 0
                let white = 0
                let yellow = 0
                let pink = 0

                switch (choice) {
                  case "red":
                    red = bet      
                    break;
                  case "green":
                    green = bet
                    break;
                  case "blue":
                    blue = bet
                    break;
                  case "yellow":
                    yellow = bet
                    break;
                  case "white":
                    white = bet
                    break;
                  case "pink":
                    pink = bet
                    break;
                }

                betDetails = {
                  roundNumber: this.eventStore.currentRound.roundNumber,
                  red: red,
                  green: green,
                  blue: blue,
                  yellow: yellow,
                  white: white,
                  pink: pink,
                };

                break;
            }

            
            axios
              .post(
                "api/event/" +
                  gameType +
                  "/" +
                  this.eventStore.eventDetails.eventId +
                  "/bet/",

                betDetails

                // "currentBalance": this.accountStore.chips ,
              )
              .then((response) => {
                this.accountStore.chips -= bet;

                switch (choice) {
                  case "meron":
                    this.meronBet += bet;

                    break;

                  case "wala":
                    this.walaBet += bet;
                    break;

                  case "draw":
                      this.drawBet += bet;
                      break;

                  case "red":
                    this.colorGameStore.bets.red += bet;
                    break;
                  case "green":
                    this.colorGameStore.bets.green += bet;
                    break;
                  case "blue":
                    this.colorGameStore.bets.blue += bet;
                    break;
                  case "yellow":
                    this.colorGameStore.bets.yellow += bet;
                    break;
                  case "white":
                    this.colorGameStore.bets.white += bet;
                    break;
                  case "pink":
                    this.colorGameStore.bets.pink += bet;
                    break;
                }

                Swal.fire({
                  title: "Good Luck!",
                  text: "Your bet has been placed.",
                  icon: "success",
                  background: "#2A3042",
                  color: "white",
                });
              })
              .catch((error) => {
                
                Swal.fire({
                  title: "You do not have enough chips",

                  icon: "warning",
                  background: "#2A3042",
                  color: "white",
                });
              });
          } else {

            if (this.accountStore.chips <= bet)
            Swal.fire({
              title: "You do not have enough chips",

              icon: "warning",
              background: "#2A3042",
              color: "white",
            });

            else if (bet <= this.eventStore.eventDetails.minimumBet) 
            Swal.fire({
              title: `the minimum bet is ${this.eventStore.eventDetails.minimumBet}`,

              icon: "warning",
              background: "#2A3042",
              color: "white",
            });
          }
        }
      });
    },

    getBets(eventName, gameType, latestround) {
      axios(`/api/event/${gameType}/bets/me/?event=${eventName}&order_by=-created_at`)

     
      
      .then(
        (response) => {

          console.log(response.data.results[0])
          this.betHistory = response.data;

          switch (gameType) {
            case "sabong":

            this.meronBet = 0
            this.walaBet = 0
            this.drawBet = 0 
            
              for (var i = 0; i < response.data.results.length; i++) {
                if (response.data.results[i].roundNumber == latestround.roundNumber) {
                  if (response.data.results[i].choice == "meron") {
                    this.meronBet += response.data.results[i].amount;
                  } else if (response.data.results[i].choice == "wala") {
                    this.walaBet += response.data.results[i].amount;
                  }
                  else if (response.data.results[i].choice == "draw") {
                    this.drawBet += response.data.results[i].amount;
                  }
                }
              }

              this.playerStore.computeWinnings(latestround);

              break;

            case "color-game":
              for (var i = 0; i < response.data.length; i++) {
  
                if (response.data[i].roundNumber == latestround.roundNumber) {
                  this.colorGameStore.bets.red = response.data[i].red;
                  this.colorGameStore.bets.green = response.data[i].green;
         
                  this.colorGameStore.bets.blue = response.data[i].blue;
                  this.colorGameStore.bets.yellow = response.data[i].yellow;
                  this.colorGameStore.bets.white = response.data[i].white;
                  this.colorGameStore.bets.pink = response.data[i].pink;
                }
               
              }

             

           

              break;
          }
        }
      );
    },
  },
});
