<template>
 <router-view></router-view>
</template>

<script>
import axios from 'axios';
import router from './router/index'

export default {
  name: 'App',

  
  components: {
  },



methods: {




}
}
</script>
