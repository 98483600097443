import { defineStore } from "pinia";
import { useAccountStore } from "./account";
import { useEventStore } from "/src/stores/events";



export const useSabongStore = defineStore({
  id: "sabong",

  state: () => ({
  
    accountStore: useAccountStore(),
    eventStore: useEventStore(),
   

   
   oddsMeron: 0,
   oddsWala: 0,
   meronPayout: 0,
   walaPayout: 0,

   winning: {
    meron: 0,
    wala: 0,
    draw: 0


   },

   total: {
    meron: 0,
    wala: 0


   }
    


  }),
   

  actions: {

    computePayout(round){
      console.log(round)
      
        this.meronPayout = ((round.oddsMeron ) * (100 - this.eventStore.eventDetails.plasada) * 2).toFixed(2)
        this.walaPayout = ((round.oddsWala ) * (100 - this.eventStore.eventDetails.plasada) * 2).toFixed(2)




    },

    oddsCompute(round){

      this.oddsMeron = (100 / round.oddsMeron).toFixed(2)
      this.oddsWala = (100 / round.oddsWala).toFixed(2)





    }
  

  
   
  },
});
