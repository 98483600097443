

export default [
  {
    path: "/",
    name: "home",

    
    

    meta: { player: true, publicAuth: true },

    

    component: () => import("./views/common/home"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {

    },
  },

  {
    path: "/register",
    name: "Register",
    meta: { player: true, playerAuth: false },
    component: () => import("./views/account/register"),
    meta: {
  
    },
  },
  {
    path: "/register/:referral",
    name: "Register-referral",
    meta: { player: true, playerAuth: false },
    component: () => import("./views/account/register"),
    meta: {
  
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/account/forgot-password"),
    meta: {

    },
  },

  {
    path: "/404",
    name: "404",
    component: require("./views/account/404").default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations

  {
    path: "/:catchAll(.*)",
    redirect: "404",
  },


  {
    path: "/event/:gameType/:eventId",
    name: "colorgame",
    meta: { player: true, publicAuth: true },
    component: () => import("./views/common/event-home"),
  },
  //admin pages

  // {
  //   path: "/admin-login",
  //   name: "admin-login",
  //   meta: { loginPage: true},
  //   component: () => import("./views/admin/admin-login"),
  // },







  //common pages
  // {
  //   path: "/dashboard/profile",
  //   name: "profile",
  //   component: () => import("./views/common/profil"),

  // },
  {
    path: "/dashboard/settings",
    name: "settings",
    meta: { authRequired: true },
    component: () => import("./views/common/settings"),
  },

  //agent pages
  // {
  //   path: "/agent-login",
  //   name: "agent-login",
  //   meta: { agent: true },
  //   component: () => import("./views/agent/agent-login"),

  // },

  // {
  //   path: "/",
  //   name: "agent-home",
  //   meta: { agent: true, agentAuth: true },
  //   component: () => import("./views/agent/agent-home"),
  // },

];



