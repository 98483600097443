import { defineStore } from "pinia";
import { useAccountStore } from "./account";
import { useEventStore } from "/src/stores/events";



export const useColorGameStore = defineStore({
  id: "colorGame",

  state: () => ({
  
    accountStore: useAccountStore(),
    eventStore: useEventStore(),
   

   
   
    bets: {

      red: 0,
      green: 0,
      blue: 0,
      yellow: 0,
      white: 0,
      pink: 0

    },

    total: {
red: 0,
green: 0,
blue: 0,
yellow: 0,
white: 0,
pink: 0



    },


    bars: {
    red: 16.67,
    green: 16.67,
    blue: 16.67,
    yellow: 16.67,
    white: 16.67,
    pink: 16.67,
    },


    winners: [
     { winner1: '',
      winner2: '',
      winner3: ''
    }
    ]

  }),
   

  actions: {

    pushWinner(winner){
      this.winner.push(winner)
      
    }  

  
   
  }
});
