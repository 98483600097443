import { defineStore } from 'pinia'
import axios from 'axios'
import { useAccountStore } from './account'
import { usePlayerStore } from './player'
import { useSabongStore } from './sabong'
import Swal from 'sweetalert2'
import { useToast } from "vue-toastification";
const toast = useToast()

import { useColorGameStore } from './colorGame'

export const useEventStore = defineStore({
id: 'events',

state: () => ({
    playerStore : usePlayerStore(),
    accountStore : useAccountStore(),
    colorGameStore: useColorGameStore(),
    sabongStore: useSabongStore(),
    eventId: '',
    gameType: '',
    domain: 'ws://api.' + window.location.host,
  

    publicEvents:[],
    
    initialize : false,

    publicRounds: '',
    eventDetails: '',

    currentRound: '',
    barStatus: '',

    webSocket: '',

    colorGameWinners: []


}),

actions: {

  disconnectWebSocket(){

    this.webSocket.close()


    
  },

  connectWebSocket(eventId, gameType){

    let self = this;
    this.webSocket = new WebSocket(this.domain + '/event/' + gameType + '/' + eventId + "/" )
    // connection.onOpen = function(event) {
     
    // };
    this.webSocket.onmessage = function(event){
    
    
    
    
    const data = JSON.parse(event.data)
    
    // console.log("this is data", data)
    
    switch (data.type)
    {
    case "round.new":
    
    self.currentRound = data.round
    self.playerStore.clearBets()
    
    
    break;
    
    case "round.update":
    
    self.playerStore.barCompute(data.round)
    self.sabongStore.oddsCompute(data.round)
    self.playerStore.computeWinnings(data.round)
    self.sabongStore.computePayout(data.round)
    
    
    self.currentRound.meronTotal = data.round.meronTotal
    self.currentRound.walaTotal  = data.round.walaTotal

    
    self.colorGameStore.total.red  = data.round.redTotal
    self.colorGameStore.total.green   = data.round.greenTotal
    self.colorGameStore.total.blue   = data.round.blueTotal
    self.colorGameStore.total.yellow   = data.round.yellowTotal
    self.colorGameStore.total.white   = data.round.whiteTotal
    self.colorGameStore.total.pink  = data.round.pinkTotal
    
    break;
    
    case "round.winner":
    
      self.winnerDeclared(data.round.roundWinner)
    break;
    
    case "round.status":
    
      self.roundStatus(data.round.status)
    
     break;

    }}

  },



  
  async getPublicEvents(){

       

      await axios('api/event/sabong/')
      .then(response =>
      {



 

        if(!this.initialize ){
          
          for(var i = 0; i <response.data.results.length; i++)
          {
            

                this.publicEvents.push(response.data.results[i])
             


          }
          
        
       
        }




            
      }
    
      
      )  .catch(error => console.log(error))

       await axios('api/event/color-game/')
      .then(response =>
      {
     


 

        if(!this.initialize ){

          
          for(var i = 0; i <response.data.results.length; i++)
          {
            
       
                this.publicEvents.push(response.data.results[i])
             


          }
        
        this.initialize = true
        }




            
      }
    
      
      )  .catch(error => console.log(error))




  },



    getEventDetails(eventId,gameType) {   

      this.eventId = eventId
      this.gameType = gameType

      axios.get('/api/event/' + gameType + "/" + eventId )
          .then(response => {

              this.eventDetails = response.data

              console.log("event details", this.eventDetails)


              this.getPublicRounds()
            









          })

          .catch(error => { console.log(error.data) })








  },

  getPublicRounds() {   



    axios.get('/api/event/'+ this.eventDetails.gameType + "/" + this.eventDetails.eventId + "/rounds")
        .then(response => {


         
            this.publicRounds = response.data

            this.currentRound = response.data[0]

          
            if (this.eventDetails.gameType == 'sabong')
            {
              this.sabongStore.computePayout(response.data[0])
              this.sabongStore.oddsCompute(response.data[0])

              this.playerStore.barCompute(response.data[0])
              
              this.playerStore.computeWinnings(response.data[0])
             
            }

     

           

            if (this.accountStore.role =='player')
            this.playerStore.getBets(this.eventDetails.eventName, this.eventDetails.gameType, response.data[0])

            
           
           
           

           
          











        })

        .catch(error => { console.log(error) })


        








},


  
   
      
    // pusher(){


    //   // Enable pusher logging - don't include this in production
    //   Pusher.logToConsole = true;
    
    // var pusher = new Pusher('420202a4cd6d0dd716fb', {
    //   cluster: 'ap1'
    // });
    
    // var channel = pusher.subscribe('test');
    // channel.bind('test', function(data) {
    //   console.log("what is this", this.currentRound)
    //   this.currentRound = JSON.stringify(data);
    
    // });
    
    
    


    winnerDeclared(winner){

      this.currentRound.roundWinner = winner
      this.getPublicRounds()
      
      switch (winner){

      case "meron":
      toast.error( winner + " has won the fight!",
        {
          position: "top-center",
          timeout: 5000,
          closeOnClick: true,
          icon: "fa fa-solid fa-trophy",
        }
      

      );
      if (this.playerStore.meronBet > 0){
      this.accountStore.chips += this.sabongStore.winning.meron
      this.accountStore.chips += this.playerStore.meronBet
   
      }
      break;

      case "wala":
        toast( winner + " has won the fight!",
        {
          position: "top-center",
          timeout: 5000,
          closeOnClick: true,
          icon: "fa fa-solid fa-trophy",
        }
      );
      if (this.playerStore.walaBet > 0){
        this.accountStore.chips += this.sabongStore.winning.wala
        this.accountStore.chips += this.playerStore.walaBet

    
      }
      break;

      case "draw":
        toast.success( "It's a draw!!!",
        {
          position: "top-center",
          timeout: 5000,
          closeOnClick: true,
          icon: "fa fa-solid fa-trophy",
        }
      );


     
        this.accountStore.chips += this.sabongStore.winning.draw
        this.accountStore.chips += this.playerStore.drawBet
        this.accountStore.chips += this.playerStore.meronBet
        this.accountStore.chips += this.playerStore.walaBet
       
        

   


      break;


     

    

      default:

        this.colorGameWinners = winner

       

        toast.success( winner + " are your winners!",
        {
          position: "top-center",
          timeout: 5000,
          closeOnClick: true,
          icon: "fa fa-solid fa-trophy",
        }
      );
      let total = 0;
      for (let winner in  this.colorGameWinners){

      
       for (let color in this.colorGameStore.bets) {

       

            if (this.colorGameStore.bets[color] > 0){
            
              if (color == this.colorGameWinners[winner])
              {
            
              total += (this.colorGameStore.bets[color] * 2)
             
             
              }
    

            }
     
     
    }
  }


      total = total * (1-(this.eventDetails.plasada/ 100))

     

      this.accountStore.chips += total

      break;

    }






    },

    roundStatus(status){

      this.currentRound.status = status

   
   
      switch (status){
        

      case "open":
        
      toast.success("Betting is " + status,
      {
          position: "top-center",
          timeout: 5000,
          closeOnClick: true,
          icon: "fa fa-solid fa-trophy",
      })
      break;

      case "closed":
        toast.error(`Betting is closed, goodluck!`,
        {
            position: "top-center",
            timeout: 5000,
            closeOnClick: true,
            icon: "fa fa-solid fa-trophy",
        })

   
        this.getPublicRounds()


        break;

        case "cancelled":
          toast.error(`Fight has been cancelled.`,
          {
              position: "top-center",
              timeout: 5000,
              closeOnClick: true,
              icon: "fa fa-solid fa-trophy",
          })

        switch (this.eventDetails.gameType)
      {case "sabong":

      this.accountStore.chips += this.playerStore.meronBet
      this.accountStore.chips += this.playerStore.walaBet
      this.accountStore.chips += this.playerStore.drawBet
      
      break;

      case "color-game":
        this.accountStore.chips += this.colorGameStore.bets.red
        this.accountStore.chips += this.colorGameStore.bets.yellow
        this.accountStore.chips += this.colorGameStore.bets.green
        this.accountStore.chips += this.colorGameStore.bets.blue
        this.accountStore.chips += this.colorGameStore.bets.white
        this.accountStore.chips += this.colorGameStore.bets.pink

      break;

      }


          break;
  


      }






    },
    


   


}


})
