import { createWebHistory, createRouter } from "vue-router";

import { useAccountStore } from "../stores/account";
import routes from './routes'
import axios from "axios";



const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {


  const user = useAccountStore();
  // user.verifyUser();
  
  const access = localStorage.getItem('access')



  const jwt = { "token": access }

  
  axios.defaults.headers.common["Authorization"] = "JWT " + access


  if (access) {


    user.userDetails().then(() => {


      

    if (routeTo.meta.publicAuth) {
      next();
    }

    else if (user.role == "player" && !routeTo.meta.playerAuth) {


      localStorage.clear();
      next('/login');


    }

    // else if (user.role == "operator" && !routeTo.meta.adminAuth) {
    //   localStorage.clear();
 
    //   next('/admin-login');
    // }

    // else if (user.role == "admin" && !routeTo.meta.adminAuth) {
    //   localStorage.clear();
     
    //   next('/admin-login');
    // }

  

    else
      next();


   } )
    
  }

  else if (routeTo.meta.publicAuth)
    next('/login')
  else if (routeTo.meta.playerAuth)
    next('/login')
  // else if (routeTo.meta.adminAuth)
  //   next('/admin-login')
 
  else next()
})

export default router
