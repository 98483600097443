import { createStore } from 'vuex'
import axios from "axios"
import modules from './modules'

const store = createStore({

  state: {
    user: {
      username:'',
      chips: '',
      role: '',
      event: '',
      
     
    },
   
    isAuthenticated: false,
   
  },

  stateless: {},

  //  considered as setters
  mutations: {

   
              
    removeAccess(state, access){
      state.user.username = ''
      state.user.role = ''
      state.user.chips = ''
      state.user.oddsChoice = ''
   
      state.isAuthenticated= false
      
          },

      
      
     setUser(state, user){
            state.user = user
          },


          initializeSS(state, stateless){
            if (localStorage.getItem('access')){
          
              state.isAuthenticated = true
              //  state.user.username = localStorage.getItem('username')
              //  state.user.role = localStorage.getItem('role')
              


              //  state.user.oddsChoice = localStorage.getItem('oddsChoice')
               
              
            } else {
              
              state = stateless
              localStorage.clear()
            
            }
          },
         
      
        
         lastRound(){
         axios
         .get("latestRound")
         .then(response=>{
             
             this.lastround = response.data
             
      
           
            
         })
      
      
        },

         // setAccess(state, access){
    //   state.access = access
    //   state.isAuthenticated= true
      
      
    //       },


    
    //  setRefresh(state, refresh){
    //         state.refresh = refresh
    //         state.isAuthenticated= true
            
            
    //             },

    
    
  },
  actions: {




  },

  getters: {


  },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation. // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
 
})

export default store

