import { createApp } from 'vue'

import App from './App.vue'
import router from "./router";
import BootstrapVue3 from 'bootstrap-vue-3';
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';
import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { createPinia } from 'pinia'
import Clipboard from 'v-clipboard';

const pinia = createPinia()


import {
  Quasar,
  Notify
} from 'quasar'


import Maska from 'maska'
import {i18n} from "./i18n.js"


const options = {
    // You can set your default options here
};



import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import store from "./state/store";

import "../src/design/app-dark.scss";

import axios from 'axios'

import { MotionPlugin } from '@vueuse/motion';


// const domain = window.location.host
// axios.defaults.baseURL = "https://api." + domain


axios.defaults.baseURL = "http://api.localhost"







createApp(App)
  // .use(Quasar, {plugins: {Notify}})
  .use(store)
  .use(router, axios)
  // .use(require('vue-chartist'))
  .use(BootstrapVue3)
  // .use(VueApexCharts)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(i18n)
  .use(registerScrollSpy)
  .use(Maska)
  .use(Toast, {transition: "Vue-Toastification__fade"})
  .use(pinia)
  .use(Clipboard)
  .use(MotionPlugin)

  .mount('#app')


