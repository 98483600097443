import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { usePlayerStore } from "./player";
import moment from "moment/moment";

export const useAccountStore = defineStore({
  id: "accounts",

  state: () => ({
    playerStore: usePlayerStore(),
    username: "",
    cellphoneNumber: 0,
    email: "",
    chips: "",
    role: "",
    commission: 0,
    
    event: "",
    authorized: null,
    axiosUserDetails: false,

    points: 0,
    commissionPoints: 0,
   

    referrals: [],
    referralLoaded: false,

    dailyCommission: null,

    transactions: [],

    referral: null,
    referralHistory: [],
    dateToday: moment().format('YYYY-MM-DD'),
    commissionToday: 0,
    transactionSearch: ''

   
  }),

  actions: {

//     computeChipsandCommission(){

//       axios(
//         `/api/account/history?order_by=-created_at&status=completed`
//       )
//         .then((response) =>
//         {

// console.log(response.data)

// let results = response.data.results




// for (let i = 0; i < results.length; i++)
// {
  

// switch (results[i].type){

// case 'CI':
//   switch (results[i].initiator){
//     case this.username:
//       this.points += Number(results[i].amount)
     
//       console.log("points",this.points)
//       break;

//     default:
//       this.points -= Number(results[i].amount)
     
//       console.log("points",this.points)
//       break;

//   }

//   break;

// case 'CO':
//   switch (results[i].initiator){
//     case this.username:
//       this.commissionPoints -= Number(results[i].amount)
//       console.log("commission points",this.commissionPoints)
//       if ( this.commissionPoints < 0 ){
//       this.points -= Math.abs(this.commissionPoints)
//       this.commissionPoints = 0
//       console.log("points",this.points)
//       }
//       break;

//     default:
//       this.points += Number(results[i].amount)
 
//       console.log("points",this.points)
//       break;

//   }
  
//   break;

// case 'EVC':
  
//     this.commissionPoints += Number(results[i].amount)

   
//     // this.points += Number(results[i].amount)
 
//     // console.log("points",this.points)
//     console.log("commissionPoints",this.commissionPoints)
//     break;

  

// }





  

// if ((this.points+this.commissionPoints) == this.chips)
// {
  
//  return null;
// }
// }     
//     })

//   },

    requestCashInOut(command) {
    
      Swal.fire({
        title: `How much to ${command}?`,

        icon: "question",
       
        html: `
        
        <input type="number" id="amount" class="form-control" min='0' value = 0 oninput="this.value = 
        !!this.value && Math.abs(this.value) >= 0  ? Math.abs(this.value) : null"/>
        
        
        
        
        
        
        `,
        inputValue: 0,
        iconColor: "yellow",
        background: "#2A3042",
        color: "yellow",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let amount = document.getElementById("amount").value;
       
          if(command == 'cash-out')
          
          if (Number(amount) <= Number(this.chips)) {
           
            return axios
              .post(`/api/account/cashOut/`, {
                amount: amount
              
              })
              .then((response) => {console.log(response)})
              .catch((error) => {
                Swal.showValidationMessage(`Request failed: ${error}`);
              });
          } else {
            console.log(typeof commission, typeof this.commission);
            return null;
          }

          else   if(command == 'cash-in')
          {

            return axios
            .post(`/api/account/cashIn/`, {
              amount: amount
            
            })
            .then((response) => {console.log(response)})
            .catch((error) => {
              let errorMessages = "";
              for (let key in error.response.data) {
                errorMessages +=  error.response.data[key] + "\n";
              }
     
              Swal.showValidationMessage(` ${errorMessages}`);
            });

            
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed && result.value == true) {
          let amount = document.getElementById("amount").value;

          
          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "yellow",
            confirmButtonColor: "#34c38f",
            title: `${Number(amount).toLocaleString()} requested for ${command}. `,
          });
        } else if (result.isConfirmed && result.value == null) {
          Swal.fire({
            toast: true,
            icon: "error",
            iconColor: "red",
            background: "#2A3042",
            color: "red",

            title: ` Cannot cash-out more than what you have!  `,
          });
        }
      });


  

    },
    searchTransactions(date, type) {
      let dateQuery;
      let typeQuery;

      if (date == "allDates") dateQuery = "from_transaction_date=2023-01-01";
      else dateQuery = "transaction_date=" + date;

      if (type == "allTypes") typeQuery = "";
      else typeQuery = "type=" + type;

      axios(
        `/api/account/history?${typeQuery}&${dateQuery}&order_by=-created_at`
      )
        .then((response) => {
          this.transactionSearch = response.data;

      
         
         
      
        })
        .catch((error) => {});

      //    axios("/api/account/history?type=EVC&from_transaction_date=" + moment().format('YYYY-MM-DD'))
      //   .then((response) => {
      //     console.log(response)
      //     for (var i = 0; i < response.data.results.length; i++)
      //     {

      //       this.commissionToday +=  Number(response.data.results[i].amount)

      //     }

      //     console.log(this.commissionToday)

      //   })
      //   .catch((error) => {console.log(error)});
    },

    calculateCommissionToday(){

      axios(
        `/api/account/history?type=EVC&transaction_date=${this.dateToday}&limit=100000000000000&username=${this.username}`
      )
        .then((response) => {

         
          for (let i=0; i < response.data.results.length; i++){
          this.commissionToday += Number(response.data.results[i].amount)
          console.log(this.commissionToday)
          
          }

       

      
         
         
      
        })
        .catch((error) => {
console.log(error)

        });



    },

    async nextReferralHistory(axiosLink){


  
     
     let response = await axios(axiosLink).then((response) =>{

      this.referralHistory = response.data
 
        console.log(this.referralHistory)
   
      })
      .catch((error) => {
        
        this.referralHistory = "none"
     
      });

        

    },
    searchReferralHistory(referral){


      axios(
       `/api/account/referrals/${referral}/history?limit=100&type=EVB`
     )
       .then((response) => {
   
        this.referralHistory = response.data
 
        console.log(this.referralHistory)
       })
       .catch((error) => {
 
         this.referralHistory = "none"
 
 
       });
 
 
   },
    async changePassword() {
      const awaitSwal = await Swal.fire({
        html: `  <div class="table-responsive table-dark">
                       
        <table class="table mb-0 table-sm table-borderless">
          
            <tbody>
    
    
                <tr class="mb-0">
    
                 
                    <th scope = "row" class="text-dark">OLD</th>
    
                    <td class="text-danger"><input type= "password" id="oldPass" class="form-control bg-transparent text-dark"   ></td>
    
                </tr>
                <tr>
    
                <th scope = "row" class="text-dark">NEW</th>
                <td class="text-danger"><input type ="password" id="newPass" class="form-control bg-transparent text-dark"    ></td>
    
    
            </tr>
          
            <tr>
    
            <th scope = "row" class="text-dark">CONFIRM</th>
            <td class="text-danger"><input type ="password" id="confirmPass" class="form-control bg-transparent text-dark"    ></td>
    
    
        </tr>
            </tbody>
        </table>
    
    </div>
        `,

        title: "Change Password",

        toast: true,
        background: "#2A3042",
        color: "white",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Save",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const oldPass = document.getElementById("oldPass").value;

          const newPass = document.getElementById("newPass").value;
          const confirmPass = document.getElementById("confirmPass").value;

          return axios
            .post(`/api/users/set_password/`, {
              current_password: oldPass,
              new_password: newPass,
              re_new_password: confirmPass,
            })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              let errorMessages = "";
              for (let key in error.response.data) {
                errorMessages += key + ": " + error.response.data[key] + "\n";
              }
              console.error(errorMessages);

              Swal.showValidationMessage(errorMessages);
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "yellow",
            confirmButtonColor: "#34c38f",
            title: `Password Changed! `,
          });
        }
      });
    },

    async referralDetails(referral) {

      referral
      const awaitSwal = await Swal.fire({
        html: `  
                             
              <table class="table table-responsive mb-0 table-sm table-borderless text-info">
                
                  <tbody class="text-info">
          
          
                      
                      <tr>
          
                      <th class="text-warning">Chips</th>
                      <td class="text-warning">${Number(
                        referral.chips
                      ).toLocaleString()}   </td>
          
                  </tr>
                  <tr>
          
                  <th class="">e-mail</th>
                  <td class="">${referral.email}   </td>
      
              </tr>
                  <tr>
          
                  <th class="">Cell#</th>
                  <td class="">${referral.cellPhoneNumber}   </td>
      
              </tr>

              <tr>
          
              <th class="">Date joined</th>
              <td class="">${this.playerStore.formatDate(referral.date_joined)}   </td>
  
          </tr>
          <tr>
          
          <th class="">Last login</th>
          <td class="">${this.playerStore.formatDate(referral.last_login)}   </td>
      
      </tr>
      <tr class="mb-0">

             
      <th scope = "row" class="">Active</th>

      <td class="">
      <select class="form-control" style="width:100px">
  <option value="true">True</option>
  <option value="false">False</option>

</select>
      
      
      </td>

  </tr>
  <tr>
          
  <th class="">Referrer</th>
  <td class="">${referral.referred_by}   </td>

</tr>
<tr>
          
<th class="">Referrals</th>
<td class="" id ="referrals"> </td>

</tr>
              
                  </tbody>
              </table>
         
              `,


              didOpen: () => {
                const list = document.getElementById('referrals');
                for (let i = 0; i < referral.referrals.length; i++) {
                  const item = document.createElement('div');
                  item.innerHTML = referral.referrals[i].username;
                  list.appendChild(item);
                  console.log(referral.referrals[i].username)
                }
              },

        title: `<span class="text-info">${referral.username}</span>`,

        background: "#2A3042",
        color: "white",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        showConfirmButton: false,
        confirmButtonText: "Save",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const name = document.getElementById("name").value;
          const plasada = document.getElementById("plasada").value;
          const minimumBet = document.getElementById("minimumBet").value;
          const link = document.getElementById("link").value;

          console.log(name);

          return axios
            .patch(`/api/admin/event/${event.gameType}/${event.eventId}/`, {
              eventName: name,
              plasada: plasada,
              minimumBet: minimumBet,
              eventVideoLink: link,
            })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              Swal.showValidationMessage(
                `Request failed: ${error.response.data[0]}`
              );
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "yellow",
            confirmButtonColor: "#34c38f",
            title: `Event Updated! `,
            imageUrl: result.value.avatar_url,
          });
        }
      });
    },

  

    getTransactions(date, type) {
      let dateQuery;
      let typeQuery;

      
      if (date == "allDates") dateQuery = "from_transaction_date=2023-01-01";
      else dateQuery = "transaction_date=" + date;

      if (type == "allTypes") typeQuery = "";
      else typeQuery = "type=" + type;

      axios(
        `/api/account/history?${typeQuery}&${dateQuery}&order_by=-created_at&status=completed&username=${this.username}`
      )
        .then((response) => {
          

          
          this.transactions = response.data;
            console.log(this.transactions)
      
         
         
      
        })
        .catch((error) => {});

      //    axios("/api/account/history?type=EVC&from_transaction_date=" + moment().format('YYYY-MM-DD'))
      //   .then((response) => {
      //     console.log(response)
      //     for (var i = 0; i < response.data.results.length; i++)
      //     {

      //       this.commissionToday +=  Number(response.data.results[i].amount)

      //     }

      //     console.log(this.commissionToday)

      //   })
      //   .catch((error) => {console.log(error)});
    },

    async editProfile() {
      const awaitSwal = await Swal.fire({
        html: `  <div class="table-responsive table-dark">
                       
        <table class="table mb-0 table-sm table-borderless">
          
            <tbody>
    
    
                <tr class="mb-0">
    
                 
                    <th scope = "row" class="text-dark">Name</th>
    
                    <td class="text-danger"><input id="name" class="form-control bg-transparent text-dark"  value=${this.username} ></td>
    
                </tr>
                <tr>
    
                <th scope = "row" class="text-dark">Cell Number</th>
                <td class="text-danger"><input type ="number" id="plasada" class="form-control bg-transparent text-dark"  value=${this.cellphoneNumber}   ></td>
    
    
            </tr>
            <tr>
    
            <th scope = "row" class="text-dark">E-mail</th>
            <td class="text-danger"><input type ="text" id="minimumBet" class="form-control bg-transparent text-dark"  value=${this.email}  ></td>
    
    
        </tr>
       
            </tbody>
        </table>
    
    </div>
        `,

        title: "Profile",

        background: "#2A3042",
        color: "white",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Save",
        showLoaderOnConfirm: true,
        preConfirm: (amount) => {
          const name = document.getElementById("name").value;
          const plasada = document.getElementById("plasada").value;
          const minimumBet = document.getElementById("minimumBet").value;
          const link = document.getElementById("link").value;

          return axios
            .patch(`/api/admin/event/${event.gameType}/${event.eventId}/`, {
              eventName: name,
              plasada: plasada,
              minimumBet: minimumBet,
              eventVideoLink: link,
            })
            .then((response) => {})
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "yellow",
            confirmButtonColor: "#34c38f",
            title: `Event Updated! `,
            imageUrl: result.value.avatar_url,
          });
        }
      });
    },

    setCommission(username) {
      Swal.fire({
        title: "How much commission?",

        icon: "question",
        toast: true,
        html: `
        
        <input type="number" step="0.1" id="commission" class="form-control" min='0' value = 0 />
        
        
        
        
        
        
        `,
        inputValue: 0,
        iconColor: "green",
        background: "#2A3042",
        color: "green",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Send",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let commission = document.getElementById("commission").value;
          if (Number(commission) <= Number(this.commission) || this.role == 'loader') {
            return axios
              .patch(`/api/account/referrals/${username}`, {
                commission: commission
                
              })
              .then((response) => {})
              .catch((error) => {
                Swal.showValidationMessage(error.response.data.commission);
              });
          } else {
            console.log(typeof commission, typeof this.commission);
            return null;
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed && result.value == true) {
          let commission = document.getElementById("commission").value;

          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "yellow",
            confirmButtonColor: "#34c38f",
            title: `${commission}% commission have been set!  `,
          });
        } else if (result.isConfirmed && result.value == null) {
          Swal.fire({
            toast: true,
            icon: "error",
            iconColor: "red",
            background: "#2A3042",
            color: "red",

            title: ` Cannot set commission more than you have!  `,
          });
        }
      });
    },

    async getReferrals() {
      if (!this.referralLoaded) {
        await axios("/api/account/referrals/")
          .then((response) => {

         
           
            for (var i = 0; i < response.data.results.length; i++) {
              this.referrals.push(response.data.results[i]);
            }

          
            this.referralLoaded = true;
          })

          .catch((error) => {
            console.log(error);
          });
      }
    },

    // async verifyUser(){

    //   const jwt = { "token": this.access }

    //   axios.defaults.headers.common["Authorization"] = "JWT " + this.access

    //   await axios.post('/api/jwt/verify/', jwt)
    //   .then(response => {

    //       //retrieves user details
    //       axios
    //         .get('/api/users/me/')
    //         .then(response => {

    //           const user = response.data

    //           this.username = user.username
    //           this.role = user.acc_type
    //           this.chips = user.chips

    //            this.authorized = true

    //         })

    //   })
    //   .catch(error => {

    //     this.refreshToken()

    //   })

    // },

    // async refreshToken(){
    //   const refreshToken = { "refresh": localStorage.getItem('refresh') }
    //   await axios.post('/api/jwt/refresh/', refreshToken)
    //   .then(response => {

    //     localStorage.setItem('access', response.data.access)

    //     axios.defaults.headers.common["Authorization"] = "JWT " + response.data.access

    //     console.log("token refreshed")

    //     this.authorized = true

    //     location.reload()

    //   })
    //   .catch(error => {

    //     this.authorized = false
    //     console.log("authorized?", this.authorized)

    //   })

    // },

    receiveChips(username) {
      Swal.fire({
        title: "How much to cash out for  " + username + "?",

        icon: "question",
        toast: true,
        html: `
        
        <input type="number" id="chips" class="form-control" min='1' value = 0 oninput="this.value = 
        !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"/>
        
        
        
        
        
        
        `,
        inputValue: 1,
        iconColor: "red",
        background: "#2A3042",
        color: "red",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Send",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let amount = document.getElementById("chips").value;
        
            return axios
              .post(`/api/account/receive_chips/`, { amount: amount, to: username })
              .then((response) => {})
              .catch((error) => {
                Swal.showValidationMessage(` ${error.response.data}`);
              });
         
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed && result.value == true) {
          let amount = document.getElementById("chips").value;

          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "green",
            confirmButtonColor: "#34c38f",
            title: `  ${username} cashed out ${amount}! `,
          });
        } else if (result.isConfirmed && result.value == null) {
          Swal.fire({
            toast: true,
            icon: "error",
            iconColor: "red",
            background: "#2A3042",
            color: "red",

            title: `Insufficient Chips!  `,
          });
        }
      });
    },

    sendChips(username) {
      Swal.fire({
        title: "How much to send to " + username + "?",

        icon: "question",
        toast: true,
        html: `
        
        <input type="number" id="chips" class="form-control" min='1' value = 0 oninput="this.value = 
        !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"/>
        
        
        
        
        
        
        `,
        inputValue: 1,
        iconColor: "yellow",
        background: "#2A3042",
        color: "yellow",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Send",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let amount = document.getElementById("chips").value;
          if (Number(amount) <= this.chips || this.role == "admin" || this.role == 'superadmin') {
            return axios
              .post(`/api/account/send_chips/`, {
                amount: amount,
                to: username,
                note: null
              })
              .then((response) => {
                if (this.role != "admin") this.chips -= amount;
              })
              .catch((error) => {
                let errorMessages = "";
                for (let key in error.response.data) {
                  errorMessages += error.response.data[key] + "\n";
                }
                console.error(errorMessages);

                Swal.showValidationMessage(errorMessages);
              });
          } else return null;
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed && result.value == true) {
          let amount = document.getElementById("chips").value;

          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "yellow",
            confirmButtonColor: "#34c38f",
            title: `Sent ${amount} chips to ${username}! `,
          });
        } else if (result.isConfirmed && result.value == null) {
          Swal.fire({
            toast: true,
            icon: "error",
            iconColor: "red",
            background: "#2A3042",
            color: "red",

            title: `Insufficient Chips!  `,
          });
        }
      });
    },

    removeAccess() {
      localStorage.clear();
    },

    async userDetails() {
      //retrieves user details
      await axios
        .get("/api/users/me/")
        .then((response) => {

          console.log(response.data)
          this.authorized = true;

          const data = response.data;

          this.username = data.username;
          this.role = data.acc_type;
          this.cellphoneNumber = data.cellphoneNumber;
          this.commissionPoints = data.commissionPoints;

          this.email = data.email;
          this.chips = data.chips;
          this.commission = data.commission;
          this.axiosUserDetails = true;

          // this.playerStore.getDownline()
        })
        .catch((error) => {
          console.log(error);

          const refreshToken = { refresh: localStorage.getItem("refresh") };
          axios
            .post("/api/jwt/refresh/", refreshToken)
            .then((response) => {
              localStorage.setItem("access", response.data.access);

              axios.defaults.headers.common["Authorization"] =
                "JWT " + response.data.access;

              console.log("token refreshed");

              location.reload();
            })
            .catch((error) => {
              this.authorized = false;
              console.log("please login again.");
              localStorage.clear();
              location.reload();
            });
        });
    },
  },

  getters: {},
});
